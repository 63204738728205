* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
  color: rgb(61, 61, 61);
  background: rgb(247 246 255);
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

p, h3 {
  margin: 16px 0px;
}

.home-container-2 {
  margin: 50px 100px;
}

.home-world-class-title {
  font-size: 35px;
  padding-left: 40px;
}

.logo-decoration {
  /* text-decoration: underline rgb(77, 148, 255) solid 3px; */
  background: linear-gradient(100deg, rgb(77, 148, 255) 0%, rgb(0, 102, 255) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translateY(18px);
  transition: all 0.9s ;
  /* animation: .90s shake infinite alternate; */
  font-size: larger;
}

/* @keyframes shake { */
  /* 0% { transform: skewY(0deg); } */
  /* 1% { transform: skewY(1deg); } */
  /* 10% { transform: skewX(-15deg); } */
  /* 15% { transform: skewX(15deg); } */
  /* 1000% { transform: skewY(0deg); } */
  /* 100% { transform: skewY(0deg); }   */
/* } */

.home-world-class-title span,
.outsourcing-container span,
.perspective-title span,
.cutting-edge-title span,
.tell-us-title span {
  text-decoration: underline rgb(77, 148, 255) solid 5px;
}

.home-card-container {
  display: flex;
}

.outsourcing-container {
  background-color: #fff;
  padding: 180px 50px 50px;
}

.outsourcing-container h1 {
  padding-left: 30px;
}

.outsourcing-subtitle {
  margin-bottom: 50px;
  font-weight: 400;
  font-size: 20px;
  padding-left: 30px;
}

.perspective-container {
  padding: 70px 120px;
  padding-bottom: 0px;
}

.perspective-title {
  padding-left: 30px;
}

.perspective-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.detail-container {
  padding-top: 110px;
  background: #fff;
}

.buildup-container, .benefit-container, .cutting-edge-container, .tell-us-container {
   background: #fff;
   padding: 40px 0px;
}

.p-top {
padding-top: 140px;
}

.cutting-edge-cards {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 100px;
  margin: 0px 60px;
}

.cutting-edge-cards div {
  margin: 0px;
  width: 310px;
}

.cutting-edge-cards div:hover {
  transform: translateY(0px);
  transition: all 0.4s ease-in-out 0s;
}

.cutting-edge-title, .tell-us-title {
    text-align: center;
    font-size: 38px;
    padding: 0px auto;
    margin: 0px 250px 45px;
}

.tell-us-form-container {
  display: flex;
  padding: 0px 100px;
  margin: 0px 60px;
}
.next-steps-detail {
  align-self: center;
  margin-left: 35px;
}

.next-steps-title {
  font-size: 33px;
  margin: 20px 0px;
}

.next-steps {
  padding-left: 17px;
}

.next-steps li {
  font-size: 24px;
  color: rgba(61, 61, 61, 0.7);
  font-weight: 500;
  margin-bottom: 30px;
}

#logo {
  display: inline-block;
  height: 100%;
  padding-left: 10px;
}
.photo {
  width: 64px;
  height: 64px;
}

.brand-logo {
  display: inline-flex !important;
}

.text-decoration__remove{
  text-decoration: none;
}